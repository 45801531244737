import { jsx as _jsx } from "react/jsx-runtime";
//
import { TreeViewCollapseIcon, TreeViewExpandIcon, TreeViewEndIcon } from './CustomIcons';
// ----------------------------------------------------------------------
export default function TreeView(theme) {
    return {
        MuiTreeView: {
            defaultProps: {
                defaultCollapseIcon: _jsx(TreeViewCollapseIcon, { sx: { width: 20, height: 20 } }),
                defaultExpandIcon: _jsx(TreeViewExpandIcon, { sx: { width: 20, height: 20 } }),
                defaultEndIcon: _jsx(TreeViewEndIcon, { sx: { color: 'text.secondary', width: 20, height: 20 } }),
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    ...theme.typography.body2,
                },
                iconContainer: {
                    width: 'auto',
                },
            },
        },
    };
}
